import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    pavilionService: "pavilionService",
    infoBoxService: "infoBoxService",
    areaService: "areaService",
    eventService: "eventService",
    taxonomyService: "taxonomyService"
  },
})
export default class PavilionForm extends mixins(EntityForm) {
  model = {};
  internal_events = [];
  internal_areas = [];
  internal_info_box = [];
  internal_event_languages = null;
  taxonomies = [];
  topView = "top-view";
  cardHorizontalScrollingView = "card-horizontal-scrolling-view";
  cardNewLineView = "card-new-line-view";
  isEditMode = false;

  get elements() {
    return [
      {
        id: "idArea",
        label: "Area",
        type: "select",
        items: this.areas,
        required: true,
        returnObject: false,
        onAction: (idArea) => {
          this.areas.forEach(async (area) => {
            if (area.value == idArea) {
              this.internal_event_languages = await this.eventService.relatedLanguages(area.idEvent);
            }
          });
        }
      },
      {
        id: "code",
        label: this.translations.labels.hall_form_code,
        type: "text",
        required: true,
        rules: "min:4",
        onAction: this.noSpaces,
      },
      {
        id: "idInfoBox",
        label: "InfoBox",
        type: "select",
        required: false,
        items: this.infoBoxList,
      },
      {
        id: "layout",
        label: "Layout",
        type: "select",
        items: [
          {
            value: this.topView,
            label: "Top view"
          },
          {
            value: this.cardHorizontalScrollingView,
            label: "Card horizontal scrolling view"
          },
          {
            value: this.cardNewLineView,
            label: "Card new line view"
          }
        ],
        required: true,
        returnObject: false,
      },
      {
        id: "cardWithDescription",
        label: this.translations.labels.pavilion_form_cardWithDescription,
        type: "checkbox",
        required: false,
        disabled: this.model.layout == this.topView
      },
      {
        id: "visualizeTagOnCards",
        label: this.translations.labels.pavilion_form_visualizeTagOnCards,
        type: "checkbox",
        required: false,
        default: true,
        disabled: this.model.layout == this.topView
      },
      {
        id: "taxonomiesIds",
        label: this.translations.labels.pavilion_form_taxonomies,
        items: this.taxonomiesOptions,
        type: "autocomplete",
        multiple: true
      },
      {
        id: "order",
        label: this.translations.labels.pavilion_form_order,
        type: "number",
        rules: "gt:0|lte:32767",
        required: true
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.form_localizations,
        languages: this.internal_event_languages,
        resetColumns: true,
        elements: [
          {
            id: "name",
            label: "Name",
            type: "text",
            required: true,
          },
          {
            id: "imageUri",
            label: this.translations.labels.hall_form_backgroundImage,
            type: "media",
            mediaType: "background",
            required: true,
          },
        ],
      },
    ];
  }

  noSpaces(evt) {
    let charCode = evt.charCode;
    if (charCode === 32) evt.preventDefault();
    return true;
  }

  get event() {
    const _event = this.internal_events.filter(
      (e) => {
        return e.id == this.model.idEvent;
      }
    );
    if (_event.length > 0) {
      return _event[0];
    }
    return null;
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get areas() {
    return this.internal_areas.map((i) => {
      return {
        label: i.code,
        value: i.id,
        idEvent: i.idEvent
      };
    });
  }

  get infoBoxList() {
    let output = this.internal_info_box.map((i) => {
      return {
        label: i.code,
        value: i.id,
      };
    });

    output.push({
      label: "No info box",
      value: null,
    });
    return output;
  }

  get helpLink() {
    return this.translations.pageHelp.pavilion;
  }

  get taxonomiesOptions() {
    return this.taxonomies.map(t => {
      return {
        label: t.code,
        value: t.id
      };
    });
  }

  get languages() {
    if (this.areas.idEvent || this.events)
      return [];
    const event = this.events.filter((e) => e.value == this.areas.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {
  }

  async created() {
    this.internal_events = await this.eventService.list();
    this.internal_areas = await this.areaService.list();
    this.internal_info_box = await this.infoBoxService.list(this.$route.params.id);
    this.taxonomies = await this.taxonomyService.list();

    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];

    this.afterCreate();
  }
}
