<script>
import PavilionForm from "@/views/menu/pavilion/pavilionForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    pavilionService: "pavilionService",
  },
})
export default class PavilionDetail extends mixins(PavilionForm) {
  formId = "detail-pavilion-form";

  get decoratedElements() {
    return this.insertAfter(this.allReadonlyFields, {
      id: "entityLink",
      label: this.translations.labels.common_form_entity_link,
      type: "text",
      readonly: true,
      copyToClipboard: true,
    });
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.pavilions_detail;
  }

  async afterCreate() {
    this.model = await this.pavilionService.read(this.$route.params.id);
    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "Pavilion", this.model.id);
  }
}
</script>
